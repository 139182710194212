// Core
import { useState } from "react";
import { useTranslation } from "next-i18next";
import cx from "classnames";

// Definitions
import type {
  YoutubePlayerOptionsType,
  YoutubePlayerPropsType,
} from "components/ui/YoutubePlayer/YoutubePlayer";
import { UserCountryCodesEnum } from "bus/user/models";

// Bus
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Components
import { Grid } from "antd";
import { Image } from "components/ui/Image";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";

// Utils
import { introRetailer } from "data/intro";
import st from "./Styles.module.less";

const RETAILER_FR_VIDEO_ID = "vJzf6ovZHkY";
const RETAILER_EN_VIDEO_ID = "O-nyEgy4_r8";
const RETAILER_DE_VIDEO_ID = "anc6qN2KOvs";
const getRetailerVideoOps = ({ language }: { language: string }): YoutubePlayerOptionsType => {
  return {
    playerVars: {
      autoplay: 0,
      loop: 1,
      rel: 0,
      showinfo: 0,
      fs: 1,
      modestbranding: 1,
      // enablejsapi: 1,
      hl: language,
    },
  };
};

export const RetailerVideo = () => {
  const [loading, setLoading] = useState(true);
  const { md = true, lg = true } = Grid.useBreakpoint();
  const { t, i18n } = useTranslation(["intro"]);
  const { userCountry } = useUserCountry();

  const YoutubePlayerDynamic = DynamicImport<YoutubePlayerPropsType>(
    import("components/ui/YoutubePlayer"),
    "YoutubePlayer",
  );

  const handleReady = () => setLoading(false);

  const videoStyles = cx(st["retailer-video"], {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });

  const videoOpts: YoutubePlayerOptionsType = getRetailerVideoOps({ language: i18n.language });
  const videoAltJSX = t(introRetailer.video.alt);
  const videoSrc = introRetailer.video.src;
  const getVideoId = (language?: string) => {
    if (language === UserCountryCodesEnum.fr) {
      return RETAILER_FR_VIDEO_ID;
    }
    if (language === UserCountryCodesEnum.de) {
      return RETAILER_DE_VIDEO_ID;
    }
    if (language === UserCountryCodesEnum.at) {
      return RETAILER_DE_VIDEO_ID;
    }

    return RETAILER_EN_VIDEO_ID;
  };
  const videoId = getVideoId(userCountry);

  return (
    <div className={videoStyles}>
      {loading && (
        <div className={st["retailer-video-preview"]}>
          <Image src={videoSrc} alt={videoAltJSX} width={1064} height={592} placeholder="blur" />
        </div>
      )}
      <YoutubePlayerDynamic videoId={videoId} options={videoOpts} onReady={handleReady} />
    </div>
  );
};
